import React, { useEffect } from 'react'

import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setSuccessMessage } from 'state'

const SnackBarSuccess = (props) => {
  const success = useSelector(state => state.global.success)
  const dispatch = useDispatch()

  useEffect(() => {
    if(success && success !== ''){
      setTimeout(() => dispatch(setSuccessMessage('')), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <Snackbar open = { (success && success !== '') ? true : false }  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} key='top_center_success'>
      <Alert severity='success' variant='filled' sx={{ width: '100%' }}>
        { success }
      </Alert>
    </Snackbar>
  )
}

export { SnackBarSuccess}
import React, { lazy, useEffect, useState } from 'react'

import { setIsPageLoading, setSession } from 'state'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import loaderSvg from 'assets/bus.svg'
import { GuestLayout } from 'components/Layout/GuestLayout'
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth'
import authenticatedRoutes from './AuthenticatedRoutes'
import unauthenticatedRoutes from './UnauthenticatedRoutes'

const Layout = lazy( () => import('components/Layout/Layout'))
const NotFound = lazy( () => import('pages/NotFound'))  

const Loading = () => {
  return (
    <Box sx={{ width: '100%', height: '50vh' }} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box sx={{ width: '10%' }}>
        <img src={loaderSvg} alt="loader"  />
      </Box>
    </Box>
  )
}

const Router = () => {

  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const session = useSelector((state) => state.global.session)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchingUser = async () => {
      try{
        setIsLoading(true)
        dispatch(setIsPageLoading(true))
        const userAttributes = await fetchUserAttributes()
        
        const { tokens } = await fetchAuthSession()
        const groups = tokens?.accessToken?.payload?.['cognito:groups']

        dispatch(setSession({
          ...userAttributes,
          groups
        }))        
      }catch(err){
        dispatch(setSession(null))
      }finally{
        // setTimeout(() => setIsLoading(false), 5000)
        setIsLoading(false)
        dispatch(setIsPageLoading(false))
      }
    }
    
    fetchingUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(isLoading)
      return;

    const { pathname } = location

    const isAuthenticatedRoute = authenticatedRoutes.find(route => route.path === pathname) ? true : false
    const isUnauthenticatedRoute = unauthenticatedRoutes.find(route => route.path === pathname) ? true : false

    if(pathname === '/'){
      navigate(session ? '/dashboard' : '/login')
      return;
    }


    if(isAuthenticatedRoute && !session){
      navigate('/login')
      return;
    }

    if(isUnauthenticatedRoute && session){
      navigate('/dashboard')
      return;
    }

  }, [session, isLoading, location, navigate])

  return(
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
        {
          authenticatedRoutes?.map(route => <Route key={route.path} {...route} element={isLoading ? <Loading /> : route.element } />)
        }
      </Route>
      <Route path='/' element={<GuestLayout />}>
        {
          unauthenticatedRoutes.map(route => <Route key={route.path} {...route} element={isLoading ? <Loading /> : route.element } />)
        }
      </Route>

      <Route title='Error Page' path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Router
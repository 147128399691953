import React from 'react'

import { Avatar, Box, Card, CardContent, Link, Typography, useTheme } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import LogoImage from "assets/logo.svg";
import { SnackBarSuccess } from 'components/common/Alert';

const GuestLayout = ( props ) => {

  const theme = useTheme()
  const { pathname } = useLocation()

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ minHeight: '100vh', backgroundColor: theme.palette.primary.dark, flexDirection: 'column' }}>

      <SnackBarSuccess />
      
      <Card sx={{ maxWidth: 400, m: 4, boxShadow: 3, borderRadius: 1 }}>
        <CardContent sx={{ padding: theme.spacing(5), flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
          <Avatar alt='Avanza Logo' src={LogoImage} sx={{ width: 'auto', height: "auto", borderRadius: 0, margin: 'auto', padding: "1rem 0 2rem 0" }} />

          <Box mb={theme.spacing(2)}>
            <Typography variant='h4' color={theme.palette.secondary.main} gutterBottom align='center'>
              ROUTE ROVER
            </Typography>
            <Typography variant='h6' color={theme.palette.secondary.main} gutterBottom align='center'>
              CENTRO DE ADMINISTRACIÓN GTFS
            </Typography>
          </Box>
          
          <Outlet />

          <Box my={2}>
            {
              pathname === '/login' && (
                <Typography align="center">
                  <Link href="/reset-password" color={theme.palette.secondary.main}>¿Olvidaste tu contraseña?</Link>
                </Typography>
              )
            }

            {
              pathname === '/signup' && (
                <Typography align="center" mt={2}>
                  ¿No has confirmado tu cuenta? <Link href="/confirm-sign-up" color={theme.palette.secondary.main}>Continua aquí</Link>
                </Typography>
              )
            }

            {
              pathname !== '/login' ? (
                <Typography align='center' mt={2}>
                  ¿Ya tienes cuenta? <Link href="/login" color={theme.palette.secondary.main}>Incia sesión</Link>
                </Typography>
                ) : (
                  <Typography align='center' mt={2}>
                    ¿No tienes cuenta? <Link href="/signup" color={theme.palette.secondary.main}>Registrate</Link>
                  </Typography>
                )
            }
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export { GuestLayout }
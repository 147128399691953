import React, { lazy } from 'react'

const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const ConfirmSignUp = lazy(() => import('pages/ConfirmSignUp'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ConfirmResetPassword = lazy(() => import('pages/ConfirmResetPassword'));
const UpdateTempPassword = lazy(() => import('pages/UpdateTempPassword'));

const routes = [
  {
    title: 'Login',
    path: '/login',
    element: <Login />,
  },
  {
    title: 'Sign Up',
    path: '/signup',
    element: <SignUp />,
  },
  {
    title: 'Reset Password',
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    title: 'Confirm Reset Password',
    path: '/confirm-reset-password',
    element: <ConfirmResetPassword />,
  },
  {
    title: 'Confirm Sign Up',
    path: '/confirm-sign-up',
    element: <ConfirmSignUp />,
  },
  {
    title: 'Update Temp Password',
    path: '/update-temp-password',
    element: <UpdateTempPassword />,
  }
]

export default routes
import React, { useEffect } from 'react'

import { setWarningMessage } from 'state'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Snackbar, Typography } from '@mui/material'

export const SnackbarWarning = () => {
  const warning = useSelector(state => state.global.warning)

  const dispatch = useDispatch()

  useEffect(() => { 
    if(warning && warning !== ''){
      setTimeout(() => dispatch(setWarningMessage('')), 10000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning])

  return (
    <Snackbar open = { (warning && warning !== '') ? true : false }  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} key='top_center_warning'>
      <Alert severity='warning' variant='filled' sx={{ width: '100%' }}>
        { 
          warning?.split('\n').map((text, index) => (
            <Typography key = { index } component={'p'}>
              { text}
            </Typography>
          )) 
        }
      </Alert>
    </Snackbar>
  )
}
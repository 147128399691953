import React from "react";

import globalReducer from "state";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import App from "./App";
import awsmobile from "./aws-exports";

import "./index.css";
import "./index.css";

// import { api } from "state/api";

if(process.env.NODE_ENV !== 'development'){
  console.log = function () {}
}

Amplify.configure({
  ...awsmobile,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_ARTIFACTS_BUCKET,
      region: 'us-east-1',
    },
  },
});

const store = configureStore({
  reducer: {
    global: globalReducer,
    // [api.reducerPath]: api.reducer,
  },
  middleware: (getDefault) => getDefault(),
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // StrictMode is making double call to useEffect, make sure to add in production the issue only happens in development
  // Change React.Fragment to React.StrictMode in production
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

import React, { useEffect } from 'react'

import { Alert, Fade, Typography } from '@mui/material'

const InlineError = ( props ) => {

  const { error, clearError, time = 5000, ...alertProps } = props

  useEffect(() => {
    if(error && error !== '' && clearError) {
      setTimeout(() => clearError(), time)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if(!error || error === '') return null

  return (
    <Fade  in = { error && error !== ''}  >
      <Alert 
        severity='error' 
        {...alertProps}
        sx={{ '.MuiAlert-icon': {
          alignItems: 'center',
        } }}>
        { 
          error.split('\n').map((text, index) => {
            if(!text) return null;
            return (<Typography 
              component={'p'} 
              sx={{ mb: 0, fontStyle: 'italic' }}
              key = { index }>
              { text }
            </Typography>)
          }) 
        }
      </Alert>
    </Fade>
  )
}

export { InlineError }
import React, { useEffect } from 'react'

import { Alert, Snackbar, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorMessage } from 'state'

export const SnackbarError = () => {
  const error = useSelector(state => state.global.error)

  const dispatch = useDispatch()

  useEffect(() => { 
    if(error && error !== ''){
      setTimeout(() => dispatch(setErrorMessage('')), 10000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <Snackbar open = { (error && error !== '') ? true : false }  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} key='top_center_error'>
      <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
        { 
          error?.split('\n').map((text, index) => (
            <Typography key = { index } component={'p'}>
              { text}
            </Typography>
          )) 
        }
      </Alert>
    </Snackbar>
  )
}
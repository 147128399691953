import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: undefined,
  session: null,
  isSidebarOpen: true,
  success: null,
  error: null,
  warning: null,
  isPageLoading: false
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setName: (state, user) => { 
      state.user = user
    },
    setSession: (state, session) => {
      state.session = session.payload
    },

    setIsSidebarOpen: (state, value) => {
      state.isSidebarOpen = typeof value.payload === 'boolean' ? value.payload : !state.isSidebarOpen
    },

    setSuccessMessage: (state, value) => {
      state.success = value.payload 
    },

    setErrorMessage: (state, value) => {
      state.error = value.payload
    },

    setWarningMessage: (state, value) => {
      state.warning = value.payload
    },

    setIsPageLoading: (state, value) => {
      state.isPageLoading = value.payload
    }
  },
});

export const { setMode, setName, setSession, setIsSidebarOpen, setSuccessMessage, setErrorMessage, setWarningMessage, setIsPageLoading } = globalSlice.actions;
export default globalSlice.reducer;
